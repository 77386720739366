.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-col-title{
    white-space: pre-wrap !important;
}

.save-user{
    margin-left: 10px !important;
}

.tabulator-data-tree-control{
    display:inline-block !important;
}

.formatterCell{
    display:inline-block !important;
}

body{
    font-family: 'Inter';
    font-size: 13px;
}

input[type="checkbox"] {
    transform:scale(1.1, 1.1) !important;
  }

div.tabulator-col-title:has(input){
    text-align: center !important;
}

.tabulator-col-content{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.tabulator-edit-select-list{
    font-family: 'Inter' !important;
    font-size: 13px !important;
}

.tabulator{
    font-family: 'Inter' !important;
    font-size: 13px !important;
}

.tabulator-header-filter input{
    font-family: 'Inter' !important;
    font-size: 13px !important;
}

.apexcharts-yaxis-label tspan { fill:black; }
.apexcharts-xaxis-label tspan { fill:black; }

.divider{
    width: 100%;
    height: 2px;
    background-color: lightgray;
    margin: 15px 0px 15px 0px;
}

label{
    color: #495057 ;
}

.tabulator-cell{
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    font-size: 13px !important;
}

.tabulator-paginator{
    font-size: 13px !important;
}

.tabulator .tabulator-footer .tabulator-page{
    padding-top: 4px;
    padding-bottom: 4px;
}

.low-padding-table td{
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    //font-size: 14px !important;
}

.tabulator-row{
    min-height: 2rem !important;
}

.tabulator-col{
    background-color: #ededed !important;
}

table.low-padding-table th{
    background-color: #ededed !important;
}

.tabulator-col-title{
    font-size: 13px !important;
}

label.btn {
    color: white !important;
}


.one-line-elipsis{
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    cursor: pointer;
}

// .tabulator-row.tabulator-row-even {
//     background-color: white !important;
// }

.tabulator-footer{
    background: white !important;
}

.show-filters div.tabulator-header-filter{
    display: block;
}

.hide-filters div.tabulator-header-filter{
    display: none;
}

.show-filters div.tabulator-col{
    height: 81px !important;;
}

.hide-filters div.tabulator-col{
    height: 50% !important;;
}

.show-filters div.tabulator-headers{
    height: 81px !important;;
}

.hide-filters div.tabulator-headers{
    height: 50% !important;;
}

.align-end{
    text-align: end;
}

.flex-row-space{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.form-control{
    padding: 3px 10px;
}

label{
    margin-bottom: 1px;
    color: $blue;
}

.form-label {
    margin-bottom: 1px;
}

table.lower-padding-table th{
    background-color: #ededed;
}

.lower-padding-table td{
    padding-top: 1px !important;
    padding-bottom: 1px !important;
    padding-left: 4px !important;
    padding-right: 4px !important;
    font-size: 12px !important;
}

.text-right{
    text-align: right;
}

.text-left{
    text-align: left;
}

.modal-header{
/*     background-color: #1C48E3 !important;
    border-color:  #1C48E3 !important; */
    background-color: #000000 !important;
    border-color:  #000000 !important;
    color: $white !important;
}

.widgTitle{
    color: $blue !important;
    font-size: 16px;
    font-weight: 600;
}

.widgMain{
    font-size: 32px;
    font-weight: 600;
    color: darkgray;
    margin-top: 0px;
}

.widgSec{
    font-size: 12px;
}

.widgFooter{
    font-size: 12px;
    font-weight: 600;
}

.widgCard{
    border-style: solid;
    border-color: lightgray;
    border-width: 1px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 8px;
    background-color: white;
}

.widget-container{
    display: flex; 
    flex-wrap: wrap;
    flex-direction: row; 
    gap: 20px;
}


.smallHelp{
    font-size: 12px;
    font-style: italic;
}

.sticky-th{
    position: sticky;
    width: 200px;
    min-width: 200px;
    max-width: 200px;
    left: 0px;
}

.sticky-td{
    position: sticky;
    width: 200px;
    min-width: 200px;
    max-width: 200px;
    left: 0px;
    background: white !important;
    z-index: 99;
}


.ssicon{
    background-image: url("../../images/ssicon.png");
    width: 20px;
    height: 20px;
    display: block;
}

.revicon{
    background-image: url("../../images/revision.png");
    background-size: 18px 18px;
    width: 18px;
    height: 18px;
    display: block;
}

.baseicon{
    background-image: url("../../images/baseline.png");
    background-size: 18px 18px;
    width: 18px;
    height: 18px;
    display: block;
}

.background-gradient{
    background: linear-gradient(rgb(172, 219, 255), #1C48E3);
}

.vote-button{
    padding: 10px;
    font-size: 14px;
    cursor: pointer;
    border-radius: 10px;
}

input[type="checkbox"]{
    border: 1px solid gray
}

input::placeholder, textarea::placeholder {
    color: lightgray;
    font-style: italic;
}

div.MuiInputBase-root.MuiOutlinedInput-root{
    height: 20px;
    width: 100%;
    font-family: 'Inter';
    font-size: 13px;
    padding-bottom: 3px;
}

.read-only-access input,
.read-only-access select,
.read-only-access button,
.read-only-access textarea{
    pointer-events: none;
    background-color: gainsboro !important;
    background-color: gainsboro !important;
}

.read-only-access input.always-enabled{
    background-color: initial !important;
}

.read-only-access input.always-enabled:checked{
    background-color: #1a3783 !important;
}

.read-only-access button{
    color: black !important;
}

.read-only-access label{
    pointer-events: none;
}
.read-only-access a{
    pointer-events: none;
    cursor: default;
}

.read-only-access a.always-enabled{
    pointer-events: all;
    cursor: pointer;
}

button.always-enabled, .always-enabled button{
    pointer-events: all;
    background-color: initial !important;
}

button.always-enabled:hover, .always-enabled button:hover{
    background-color: initial !important;
}

input.always-enabled, .always-enabled input{
    pointer-events: all;
    //background-color: initial !important;
    color: initial !important;
    cursor: default !important;
}

select.always-enabled, .always-enabled select{
    pointer-events: all;
    color: initial !important;
    background-color: initial !important;
    cursor: default !important;
}

label.always-enabled, .always-enabled label{
    background-color: initial !important;
    //color: initial !important;
    opacity: 1 !important;
}

input:disabled{
    background-color: gainsboro !important;
}

.resource-planning-show-filters div.tabulator-header-filter{
    display: block;
}

.resource-planning-hide-filters div.tabulator-header-filter{
    display: none;
}

.tabulator-header-filter{
    pointer-events: all;
}

.always-disabled select, .always-disabled button, .always-disabled input{
    pointer-events: none !important;
    background-color: gainsboro !important;
    cursor: default !important;
}

.always-disabled label{
    pointer-events: none !important;
    cursor: default !important;
}

.button-swal-3-buttons{
    width: 80%;
    margin: 5px;
}

.normal-button{
    margin-left: 3px;
    margin-right: 3px;
    outline: none !important;
    box-shadow: none !important;
}   

.normal-button:focus {
    box-shadow: none !important;
    outline: none !important;
}

.btn-outline-primary:hover{
    background-color: #1a3783 !important;
    color: white !important; 
} 
.btn-outline-primary:focus{
    background-color: #1a3783 !important;
    color: white !important; 
} 
.btn-outline-primary:active{
    background-color: #1a3783 !important;
    color: white !important;
} 

.sticky-table-div {
    overflow:scroll;
    flex-grow:1;
    width:100%;
  }
  
  .sticky-table {
    border-collapse: collapse;
    width:150%;
  }
  
  .sticky-table thead tr > th {
    // background:#ccc;
    position: sticky;
    padding:.5rem;
    z-index:2;
    top: 0;
  }
  
  .sticky-table thead tr > :first-child {
    z-index:3;
    left:0;
    top:0;
  }

  .sticky-table thead tr > :nth-child(2) {
    z-index:3;
    left:0;
    top:0;
  }
  
  .sticky-table td {
    text-align:center;
  }
  
  .sticky-table tbody tr > :first-child {
    background:#fff;
    position: sticky;
    z-index:1;
    left:0;
  }

  .sticky-table tbody tr > :nth-child(2) {
    background:#fff;
    position: sticky;
    z-index:1;
    left:0;
  }

  .simple-check-label{
    margin-left: 10px;
    gap: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .Difference {
    font-family: 'Inter';
  }
  
  .Difference > del {
    background-color: rgb(255, 224, 224);
    text-decoration: none;
  }
  
  .Difference > ins {
    background-color: rgb(201, 238, 211);
    text-decoration: none;
  }
  
  .e-rule-field{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .e-rule-field > div:nth-child(1){
    width: 95% !important;
  }

  .e-rule-field > div:nth-child(3){
    width: 95% !important;
    max-width: 95% !important;
  }

  .e-rule-field > div:nth-child(2){
    width: 50% !important;
  }

  .box {
   
    position: relative;
  }

  .box:not(:last-child)::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    width: 2px;
    height: 20px; 
    background-color: #1a3783;
    transform: translateX(-50%);
    
  }

  .box:not(:last-child)::before {
    content: '';
      position: absolute;
      top: calc(100% + 15px); 
      left: 50%;
      width: 10px;
      height: 10px;
      border-style: solid;
      border-width: 0 2px 2px 0;
      border-color: #1a3783;
      transform: translateX(-50%) rotate(45deg);
  }

  .webReportArea{
    width: auto !important;
    // top: 70px;
    position: static !important;
  }
  .webReportArea div.designer__content {
    position: relative !important;
  }

  .webReportArea div.designer__content div.designer__canvas {
    position: relative !important;
  }


  .webReportArea div.designer__content div.designer__canvas div.twd-toolbox.twd-designer-parts {
    position: relative !important;
  } 

  .webReportArea div.designer__content div.twd-preview.twd-designer-parts{
    position: relative !important;
  }

  .webReportArea div.designer__content div.twd-preview.twd-designer-parts div.trv-report-viewer{
    position: relative !important;
  }

  .webReportArea div.designer__content div.twd-preview.twd-designer-parts div.trv-report-viewer div.trv-content-wrapper.k-content{
    position: relative !important;
  }

  div.trv-content.trv-document-map-splitter.k-widget.k-splitter.k-pane.k-scrollable{
    position: relative !important;
    height: auto !important;
  }

 div.trv-document-map.k-widget.k-pane.k-scrollable.k-collapsed {
    position: relative !important;
 }

 div.trv-pages-area.k-widget.k-pane.k-scrollable.scrollable.interactive {
    position: relative !important;
    height: auto !important;
 }

 div.trv-page-container{
    position: relative !important;
 }

 .invalid-feedback{
    font-size: 13px;
    font-weight: 600;
 }
 
 .e-gantt .e-gantt-chart .e-gantt-child-taskbar-inner-div {
    background-color: #8e9def !important;
 }

 .e-gantt .e-gantt-chart .e-gantt-parent-taskbar-inner-div {
    background-color: #b0b0b0 !important;
 }

 button.btn-primary.always-enabled{
    background-color: #1a3783 !important;
    color: white !important;
 }

 .custom-large-tooltip{
    z-index: 99999999999999;
 }

 .custom-large-tooltip div.MuiTooltip-tooltip{
    min-width: 600px;
 }

 .sub-menu li{
    white-space: normal;
 }

.read-only-access button.always-white{
    background-color: transparent !important;
    border-color: transparent !important;
}

.syncfusion-ddl-tshirt{
    display: flex;
    flex-direction: column;
}

.e-ddl.e-input-group.e-control-wrapper.e-input-focus::before, .e-ddl.e-input-group.e-control-wrapper.e-input-focus::after {
    background: transparent !important;
}

span.e-ddl {
    border-radius: 5px !important;
    border: 1px solid lightgray !important;
    padding: 0 10px !important;
}

.round-border{
    border-radius: 5px;
}

.e-list-item > span{
   display: flex;
   flex-direction: column;
   gap: 0;
   justify-content: flex-start;
   align-items: flex-start;
   padding-bottom: 10px;
}

.btn-green{
    background: green;
    color: white;
}

.btn-green:focus{
    background: green !important;
    color: white !important;
}

.btn-red{
    background: red;
    color: white;
}

.btn-red:focus{
    background: red !important;
    color: white !important;
}


.d-flex-row{
    display: flex;
    flex-direction: row;
}

.d-flex-col{
    display: flex;
    flex-direction: column;
}

.jc-center{
    justify-content: center;
}

.jc-end{
    justify-content: flex-end;
}

.jc-space-between{
    justify-content: space-between;
}

.ai-center{
    align-items: center;
}

.ai-end{
    align-items: flex-end;
}

.w-100{
    width: 100%;
}

.h-100{
    height: 100%;
}

.alternative-card{
    padding: 8px;
    background: white;
    //box-shadow: 2px 2px lightgray;
    border-radius: 10px;
    border: 1px solid lightgray;
}

.no-background .background-gradient{
    background: none;
    color: black;
}

.anychart-tooltip{
    background: black !important;
    max-width: 500px;
    opacity: .9;
}

.e-time{
    visibility: hidden;
}

.row-with-children{
    font-weight: bold;
}

.non-working-day{
    background-color: red;
    opacity: 0.5;
}

.font-13 td, .font-13 th, .font-13 span, .font-13 p{
    font-size: 13px!important;
}

.gap-1{
    gap: 10px
}

.gap-2{
    gap: 20px;
}

.gap-3{
    gap: 30px;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
    --bs-table-accent-bg: #ffffff !important;
    color: black;
}

.table-striped > tbody > tr:nth-of-type(even) > * {
    --bs-table-accent-bg: #f9f9f9 !important;
    color: black;
}

.table-highlight tr:hover, .table-highlight tr:hover td {  
    --bs-table-accent-bg: #ededed !important;
    background: #ededed !important;
}

.alternative-rating-notes-container{
    text-align: start;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: white;
    border: 1px solid black;
    border-radius: 10px;
    padding: 10px;
}

.e-gantt-dialog .e-dlg-header-content, .e-tab-header{
    background: black !important;
    font-family: 'Inter' !important;
}

.e-flat{
    background: 'gray'!important;
    border-radius: 4px !important;
    font-family: 'Inter' !important;
}

.e-primary{
    background: #1a3783!important; 
}

.e-gantt-dialog{
    border-radius: 10px !important;
    border: 1px solid black !important;
}

.e-dlg-header-content{
    border-top-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
}

.e-footer-content{
    border-bottom-right-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
}

.e-float-input.e-input-group:not(.e-float-icon-left) .e-float-line::before, .e-float-input.e-input-group:not(.e-float-icon-left) .e-float-line::after, .e-float-input.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::before, .e-float-input.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::after, .e-float-input.e-control-wrapper.e-input-group:not(.e-float-icon-left) .e-float-line::before, .e-float-input.e-control-wrapper.e-input-group:not(.e-float-icon-left) .e-float-line::after, .e-float-input.e-control-wrapper.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::before, .e-float-input.e-control-wrapper.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::after{
    background: #1a3783 !important;
}

.e-float-text, .e-label-top{
    font-weight: 600!important;
    font-size: 13px!important;
    color: #1a3783 !important;
}

.e-control::selection{
    background: #1a3783!important;
}

.e-list-item .e-active{
    color: #1a3783 !important;
}

// .my-sticky-table {
//     position: relative;
//     height: 400px;
//     overflow: auto;
//     margin: 20px;
//   }
  
//   .my-sticky-table table {
//     border-collapse: collapse;
//     width: 100%;
//   }
  
//   .my-sticky-table thead th {
//     position: sticky;
//     top: 0;
//     background: #fff;
//     z-index: 2;
//   }
  
//   .my-sticky-table .fixed-column {
//     position: sticky;
//     left: 0;
//     background: #fff;
//     z-index: 1;
//   }
  
//   .my-sticky-table th,
//   .my-sticky-table td {
//     border: 1px solid #ddd;
//     padding: 8px;
//     text-align: left;
//   }
  
//   .my-sticky-table thead th.fixed-column {
//     z-index: 3;
//   }

.my-sticky-table {
    position: relative;
    overflow: auto;
    margin: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .my-sticky-table table {
    border-collapse: collapse;
    width: 100%;
  }
  
  .my-sticky-table thead th {
    position: sticky;
    top: 0;
    background: #f8f9fa;
    z-index: 2;
  }
  
  .my-sticky-table td[data-fixed-column],
  .my-sticky-table th[data-fixed-column] {
    position: sticky;
    background: #f8f9fa;
    z-index: 1;
  }
  
  .my-sticky-table thead th[data-fixed-column] {
    z-index: 3;
  }
  
  .my-sticky-table [data-fixed-column="1"] { left: 0; }
  .my-sticky-table [data-fixed-column="2"] { left: 150px; }
  
  .my-sticky-table th,
  .my-sticky-table td {
    border: 1px solid #dee2e6;
    padding: 12px;
    text-align: left;
    min-width: 100px;
  }
  
  .my-sticky-table tbody tr:hover {
    background-color: #f1f3f5;
  }
  
  .my-sticky-table tbody tr:hover td[data-fixed-column] {
    background-color: #f1f3f5;
  }

  .optionListContainer {
    z-index: 9999999999;
  }